import { post } from "@rails/request.js";
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [
    "materialConfigInput",
    "materialInput",
    "finishInput",
    "colorInput",
    "materialsPage",
    "finishsPage",
    "colorsPage",
    "groupsPage",
    "headerMaterial",
    "headerFinish",
    "headerColor",
  ]

  
  connect(){
    this.setGroupsList()
    this.goToGroups()
    // this.setMaterialsList()
    // this.goToMaterials()
  }

  groupSelectClicked(e){
    this.goToMaterials()

    let groupId = e.currentTarget.dataset.groupId
    this.setMaterialsList(groupId)
  }
  
  materialSelectClicked(e){
    this.goToFinishs()
    
    let selectedMaterialId = e.currentTarget.dataset.materialId
    let selectedMaterialName = e.currentTarget.dataset.materialName
    this.materialInputTarget.value = selectedMaterialId
    this.headerMaterialTarget.querySelector('span').innerText = selectedMaterialName
    this.headerMaterialTarget.classList.remove('hidden')
    let price = e.currentTarget.previousElementSibling.querySelector('.price-value').innerText.replace('€', '');
    this.setFinishList(price)
  }

  finishSelectClicked(e){
    this.goToColors()
    
    let selectedFinishId = e.currentTarget.dataset.finishId
    let selectedFinishName = e.currentTarget.dataset.finishName
    this.finishInputTarget.value = selectedFinishId
    this.headerFinishTarget.querySelector('span').innerText = selectedFinishName
    this.headerFinishTarget.classList.remove('hidden')
    let price = e.currentTarget.previousElementSibling.querySelector('.price-value').innerText.replace('€', '');
    this.setColorsList(price)
  }
  
  colorSelectClicked(e){
    this.finishsPageTarget.classList.add('hidden')
    this.colorsPageTarget.classList.remove('hidden')
    
    let selectedColorId = e.currentTarget.dataset.colorId
    let selectedColorName = e.currentTarget.dataset.colorName
    this.colorInputTarget.value = selectedColorId
    this.headerColorTarget.querySelector('span').innerText = selectedColorName
    this.headerColorTarget.classList.remove('hidden')

    this.setMaterialConfigId()
  }

  goToGroups(){
    this.headerMaterialTarget.classList.add('hidden')
    this.headerFinishTarget.classList.add('hidden')
    this.headerColorTarget.classList.add('hidden')
    
    this.groupsPageTarget.classList.remove('hidden')
    this.materialsPageTarget.classList.add('hidden')
    this.finishsPageTarget.classList.add('hidden')
    this.colorsPageTarget.classList.add('hidden')

    this.materialInputTarget.value = ""
    this.finishInputTarget.value = ""
    this.colorInputTarget.value = ""
    this.materialConfigInputTarget.value = ""
  }

  goToMaterials(){
    this.headerMaterialTarget.classList.add('hidden')
    this.headerFinishTarget.classList.add('hidden')
    this.headerColorTarget.classList.add('hidden')
    
    this.groupsPageTarget.classList.add('hidden')
    this.materialsPageTarget.classList.remove('hidden')
    this.finishsPageTarget.classList.add('hidden')
    this.colorsPageTarget.classList.add('hidden')

    this.materialInputTarget.value = ""
    this.finishInputTarget.value = ""
    this.colorInputTarget.value = ""
    this.materialConfigInputTarget.value = ""
  }

  goToFinishs(){
    this.headerFinishTarget.classList.add('hidden')
    this.headerColorTarget.classList.add('hidden')

    this.groupsPageTarget.classList.add('hidden')
    this.materialsPageTarget.classList.add('hidden')
    this.finishsPageTarget.classList.remove('hidden')
    this.colorsPageTarget.classList.add('hidden')

    this.finishInputTarget.value = ""
    this.colorInputTarget.value = ""
    this.materialConfigInputTarget.value = ""
  }

  goToColors(){
    this.headerColorTarget.classList.add('hidden')

    this.groupsPageTarget.classList.add('hidden')
    this.materialsPageTarget.classList.add('hidden')
    this.finishsPageTarget.classList.add('hidden')
    this.colorsPageTarget.classList.remove('hidden')

    this.colorInputTarget.value = ""
    this.materialConfigInputTarget.value = ""
  }

  async setGroupsList(){
    this.urlBase = this.element.dataset.materialPriceUrlBase
    let response = await post('/materials/get_groups', {
                                body: JSON.stringify({ 
                                  prices_url_base: this.urlBase
                                })
                              })
    let groups = await response.text
    document.getElementById('groups_list').innerHTML = groups
  }

  async setMaterialsList(groupId){
    this.urlBase = this.element.dataset.materialPriceUrlBase
    let response = await post('/materials/get_materials', {
                                body: JSON.stringify({
                                  material_group_id: groupId,
                                  prices_url_base: this.urlBase
                                })
                              })
    let materials = await response.text
    document.getElementById('materials_list').innerHTML = materials
  }
  
  async setFinishList(price){
    this.urlBase = this.element.dataset.materialPriceUrlBase
    let response = await post(`/materials/get_finishs`, { 
                                body: JSON.stringify({ 
                                  material_id: this.materialInputTarget.value,
                                  prices_url_base: this.urlBase, 
                                  price: price
                                }) 
                              })
    let finishs = await response.text
    document.getElementById('finishs_list').innerHTML = finishs
  }

  async setColorsList(price){
    this.urlBase = this.element.dataset.materialPriceUrlBase
    let response = await post(`/materials/get_colors`, { 
                                body: JSON.stringify({ 
                                  material_id: this.materialInputTarget.value,
                                  finish_id: this.finishInputTarget.value,
                                  prices_url_base: this.urlBase,
                                  price: price
                                }) 
                              })
    let colors = await response.text
    document.getElementById('colors_list').innerHTML = colors
  }

  async setMaterialConfigId(){
    this.urlBase = this.element.dataset.materialPriceUrlBase
    let response = await post(`/materials/get_material_config`, { 
                                body: JSON.stringify({ 
                                  material_id: this.materialInputTarget.value,
                                  finish_id: this.finishInputTarget.value,
                                  color_id: this.colorInputTarget.value,
                                  prices_url_base: this.urlBase
                                }) 
                              })
    let config = await response.json

    this.materialConfigInputTarget.value = config["material_config_id"]
    this.materialConfigInputTarget.dispatchEvent(new Event('change'))
    this.submitMultiples()
  }

  async submitMultiples() {
    let productId = this.element.dataset.productId
    let material = this.materialInputTarget.value
    let finish = this.finishInputTarget.value
    let color = this.colorInputTarget.value
    let materialConfig = this.materialConfigInputTarget.value

    let url = this.element.dataset.submitUrl
    if(material === "" || finish === "" || color === "" || materialConfig === "" || !url){
      return
    }
    let response = await post(url, {
      body: JSON.stringify({ 
        default_material_id: material,
        default_finish_group_id: finish,
        default_material_color_id: color,
        default_material_config_id: materialConfig
      })
    })
    if(response.ok){
      let data = await response.json
      window.location = data['redirectUrl']
    }
  }
}