import { get } from "@rails/request.js";
import AbortController from "abort-controller";
import ApplicationController from "./application_controller";
export default class extends ApplicationController {

  async connect() {
    console.log('Material Price Controller Connected')
    this.abortController = new AbortController()
    this.signal = this.abortController.signal
    document.addEventListener('abort', () => {
      this.abortController.abort()
    })

    this.baseUrl = this.element.dataset.baseUrl
    this.url = this.element.dataset.url
    this.id = this.element.dataset.id
    
    let response = await get(this.baseUrl + this.url + this.id, {
      signal: this.signal
    })
    let materials = await response.text
    this.element.querySelector('.price').innerHTML = materials
    let fetchedPrice = document.querySelectorAll('.price-value')
    fetchedPrice.forEach(element => {
      
      // Check if the text inside the element is zero
      console.log(element.textContent.trim())
      if (element.textContent.trim() === '0') {
        let dataId = element.getAttribute('data-id');
          // Find the element with the corresponding data-id and hide it
          let elementToHide = document.getElementById(`card-${dataId}`);
          if (elementToHide) {
              elementToHide.style.display = 'none';
          }
      }
  });
  }

  abort(){
    document.dispatchEvent(new Event('abort'))
  }
}