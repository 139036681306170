// app/javascript/packs/application.js
import "bootstrap"
import "controllers"

//= require jquery
//= require jquery_ujs
//= require turbolinks
//= require bootstrap
//= require_tree .
//= require hogan
//= require activestorage

import 'cocoon-js'
import "controllers"
// console.log("application js from packs")


document.addEventListener("show-spinner", function() {
  document.getElementById("spinner").classList.remove('spinner-none')
});

document.addEventListener("hide-spinner", function() {
  document.getElementById("spinner").classList.add('spinner-none')
});
