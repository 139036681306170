import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  connect(){
    this.materialsUrl = this.element.dataset.materialsUrl
    this.redirectUrl = this.element.dataset.redirectUrl
    this.configInput = document.getElementById('default_material_config_id')
    if(this.configInput){
      this.configInput.addEventListener('change', this.handleConfigSelected.bind(this))
    }
  }

  buttonClicked(e){
    let target = document.getElementById('changeMaterialModalContent')
    target.dataset.materialPriceUrlBase = this.materialsUrl
  }

  handleConfigSelected(e){
    let configId = this.configInput.value
    window.location = this.redirectUrl + `?material_config_id=${configId}`
  }
}