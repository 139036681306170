import { post } from "@rails/request.js";
import ApplicationController from "./application_controller";

export default class extends ApplicationController { 
  static targets= ['canvas']
  
  async uploadPart(e){
    document.dispatchEvent(new CustomEvent("show-spinner"));
    this.images = {}
    var files = e.srcElement.files

    var formData = new FormData()
    formData.append("product_id", e.srcElement.dataset.productId)

    for (let index = 0; index < files.length; index++) {
      this.images[index] = []
      var file = files[index];
      formData.append(`files[${index}]`, file)

      await this.getScreenshots(file, index)
      formData.append(`screenshots[${index}]`, this.images[index].join(' '))
    }

    let response = await post("/post_files", { body: formData })
    let data = await response.json
    window.location = data['redirectUrl']
  }

  async getScreenshots(file, index) {
    return new Promise(resolve => { 
      this.stl_viewer = new StlViewer(this.canvasTarget, {
        cameray: -50,
        models: [{ id: 1, local_file: file }],
        model_loaded_callback: () => {
          this.getPictures(index, resolve)
        },
      })
    })
  }

  getImage(index, x, y, z) {
    this.stl_viewer.rotate(1, x, y, z)
    var dataURL = this.canvasTarget.querySelector("canvas").toDataURL("image/png")
    this.images[index].push(dataURL)
  }

  getPictures(index, resolve) {
    let angles = [[0, 0, 2], [0, 0, 2], [0, 0, 2], [0, 0, 1], [-0.5, 0, 0], [-0.5, 0, 0], [-0.5, 0, 0], [-0.5, 0, 0], [-1, 0, 0]]
    var delay = 100
    angles.forEach((angle) => {
      delay += 100
      setTimeout(this.getImage.bind(this), delay, index, angle[0], angle[1], angle[2])
    })
    setTimeout(() => {
      var canvas = this.canvasTarget.querySelector("canvas")
      canvas.parentNode.removeChild(canvas)
      resolve()
    }, delay + 500)
  }
}
