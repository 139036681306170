import { post } from "@rails/request.js";
import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = [
    "fileName",
    "title",
    "canvas"
  ]

  async uploadPart(e) {
    var file = e.srcElement.files[0]
    var formData = new FormData()
    formData.append("file", file)
    if(this.hasFileNameTarget) {
      this.fileNameTarget.innerHTML = file.name
    }
    if(this.hasTitleTarget) {
      this.titleTarget.value = file.name
    }

    await this.getScreenshots(file)
    formData.append("screenshots", this.images.join(' '))

    await post(this.element.dataset.partModelUrl, { body: formData })
  }

  async uploadNewPart(e) {
    var file = e.srcElement.files[0]
    var formData = new FormData()
    formData.append("file", file)
    
    await this.getScreenshots(file)
    
    formData.append("screenshots", this.images.join(' '))
    let response = await post(this.element.dataset.partModelUrl, { 
      body: formData 
    })
    if(response.ok){
      let data = await response.json
      window.location = data['redirectUrl']
    }
  }

  async getScreenshots(file) {
    return new Promise(resolve => { 
      this.images = []
      this.stl_viewer = new StlViewer(this.canvasTarget, {
        cameray: -50,
        models: [{ id: 1, local_file: file }],
        model_loaded_callback: () => {
          this.getPictures(resolve)
        },
      })
      
    })
  }

  getImage(x, y, z) {
    this.stl_viewer.rotate(1, x, y, z)
    var dataURL = this.canvasTarget.querySelector("canvas").toDataURL("image/png")
    this.images.push(dataURL)
  }

  getPictures(resolve) {
    let angles = [[0, 0, 2], [0, 0, 2], [0, 0, 2], [0, 0, 1], [-0.5, 0, 0], [-0.5, 0, 0], [-0.5, 0, 0], [-0.5, 0, 0], [-1, 0, 0]]
    var delay = 100
    angles.forEach((angle) => {
      delay += 100
      setTimeout(this.getImage.bind(this), delay, angle[0], angle[1], angle[2])
    })
    setTimeout(() => {
      var canvas = this.canvasTarget.querySelector("canvas")
      canvas.parentNode.removeChild(canvas)
      resolve()
    }, delay + 500)
  }
}
