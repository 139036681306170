import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["billingForm", "billingCheckbox"]

  connect() {
    this.handleBillingFormDisplay()
  }

  handleBillingFormDisplay() {
    if (this.billingCheckboxTarget.checked) {
      this.billingFormTarget.classList.add("hidden")
    } else {
      this.billingFormTarget.classList.remove("hidden")
    }
  }
}
