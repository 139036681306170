import { post } from "@rails/request.js"
import ApplicationController from "./application_controller"

export default class extends ApplicationController {
  static targets = ["productQuantity", "partQuantity", "part"]

  static values = {
    configId: String,
    portalsDomId: String,
  }

  connect() {
    this.updateAllQuantities()
  }

  updateAllQuantities() {
    this.partQuantityTargets.forEach((input) => {
      input.value = this.productQuantityTarget.value
    })
    this.fetchPortals()
  }

  fetchPortals() {
    this.disableButtonsWhileLoading()

    var datas = this.partTargets.map((part) => {
      return {
        modelId: part.dataset.modelId,
        quantity: part.querySelector('input[type="number"]').value,
        part_id: part.dataset.partId
      }
    })
    let response = post("/selections/fetch_portals", {
      body: {
        models: JSON.stringify(datas),
        material_config_id: this.configIdValue,
        dom_id: this.portalsDomIdValue,
        type: "product"
      },
    })
  }

  disableButtonsWhileLoading() {
    document.querySelectorAll(".portal-select-button").forEach((button) => {
      button.disabled = true
      button.innerText = "Loading..."
    })
  }

  decreaseQuantity(event) {
    const input = event.target.nextElementSibling;
    if (input.value > 1) {
      input.value--;
      this.updateAllQuantities();
    }
  }

  increaseQuantity(event) {
    const input = event.target.previousElementSibling;
    input.value++;
    this.updateAllQuantities();
  }
}
