import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["totalPrice", "designPrice", "totalPriceWithoutDesign", "designInput", "commissionPrice"]

  priceChanged(e) {
    this.totalPriceWithoutDesignTargets.forEach((target, index) => {
      var design = this.parseFee(this.designInputTarget.value) || 0
      var printing_shipping = this.parseFee(target.dataset.price)
      var commission = this.calculateCommission(design, printing_shipping)
      var total = design + printing_shipping + commission

      this.totalPriceTargets[index].innerText = this.formatCurrency(total)
      this.designPriceTargets[index].innerText = this.formatCurrency(design)
      this.commissionPriceTargets[index].innerText = this.formatCurrency(commission)
    });
  }

  parseFee(amount) {
    return parseFloat(amount.replace(",", ".").replace("€", ""))
  }

  calculateCommission(design, printing_shipping) {
    const TELEPORT_FEE = 0.15
    return (design + printing_shipping) * TELEPORT_FEE
  }

  formatCurrency(amount) {
    return amount.toFixed(2).replace(",", ".") + "€"
  }
}
