import ApplicationController from "./application_controller"

var $fileInput = $(".file-input")
var $droparea = $(".file-drop-area")

// highlight drag area
$fileInput.on("dragenter focus click", function () {
  $droparea.addClass("is-active")
})

// back to normal state
$fileInput.on("dragleave blur drop", function () {
  $droparea.removeClass("is-active")
})

// change inner text
$fileInput.on("change", function () {
  var filesCount = $(this)[0].files.length
  var $textContainer = $(this).prev()

  if (filesCount === 1) {
    // if single file is selected, show file name
    var fileName = $(this).val().split("\\").pop()
    $textContainer.text(fileName)
  } else {
    // otherwise show number of files
    $textContainer.text(filesCount + " files selected")
  }
})

export default class extends ApplicationController {
  static targets = [
    "id",
    "fileName",
    "imagesLabel",
    "button",
    "title",
    "material",
    "finish",
    "colors",
    "size",
    "thumbnail",
    "thumbnailField",
    "infill"
  ]

  imagesUploaded(e) {
    var sentence =
      e.target.files.length > 1
        ? " images sélectionnées"
        : " image sélectionnée"
    this.imagesLabelTarget.innerHTML =
      e.target.files.length.toString() + sentence
  }

  uploadPart(e) {
    var file = e.srcElement.files[0]
    var formData = new FormData()
    formData.append("file", file)
    this.fileName = file.name
    this.fileNameTarget.innerHTML = file.name
    this.buttonTarget.disabled = true
    this.buttonTarget.innerHTML =
    "<i style='color: white;' class='fa fa-spinner fa-spin'></i>"
    this.titleTarget.value = this.fileName
    this.images = []

    $.ajax({
      type: "POST",
      url: "/post_model",
      data: formData,
      processData: false,
      contentType: false,
      success: (data) => {
        const size_x = data["dimensions"]["x"].toFixed(2) || "0"
        const size_y = data["dimensions"]["y"].toFixed(2) || "0"
        const size_z = data["dimensions"]["z"].toFixed(2) || "0"
        this.modelId = data["modelId"]
        this.thumbnail = data["thumbnailUrl"]
        this.images.push(this.thumbnail)
        this.idTarget.value = this.modelId
        this.thumbnailTarget.classList.remove("hidden")
        this.thumbnailTarget.src = this.thumbnail
        this.thumbnailFieldTarget.value = this.thumbnail
        this.fileNameTarget.innerHTML = file.name
        this.sizeTarget.value = `${size_x}x${size_y}x${size_z}`
        this.buttonTarget.innerHTML = "Add part"
        this.buttonTarget.removeAttribute('disabled')
      },
    })
  }
}
