import ApplicationController from "./application_controller";
export default class extends ApplicationController {
  static values = { price: String }
  connect() {
    console.log('Color Controller Connected')
    console.log(this.priceValue)
    if(this.priceValue == '0'){
      console.log('Price is 0')
      console.log(this.element.closest('.white-card'))
      this.element.closest('.white-card').style.display = 'none'
    }
  }

  abort(){
    document.dispatchEvent(new Event('abort'))
  }
}