import { Controller } from "stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["input", "previews", "dropArea"];

  connect() {
    this.setupDragAndDrop();
    this.setupSortable();
    console.log("Image Preview Controller Connected");
  }

  previewImages() {
    const input = this.inputTarget;
    const previewsContainer = this.previewsTarget;
    const existingFiles = Array.from(previewsContainer.querySelectorAll(".image-container img")).map(img => img.src);

    Array.from(input.files).forEach((file, index) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (!existingFiles.includes(event.target.result)) {
          const div = document.createElement("div");
          div.classList.add("image-container");
          div.dataset.index = existingFiles.length + index;
          if (existingFiles.length + index === 0) {
            div.classList.add("first-image");
          }

          const img = document.createElement("img");
          img.src = event.target.result;
          img.classList.add("preview-image");

          const span = document.createElement("span");
          span.classList.add("remove-image");
          span.innerHTML = "&times;";
          span.onclick = () => {
            div.remove();
            this.updateImageOrderInput();
            const dt = new DataTransfer();
            Array.from(input.files).forEach((f, i) => {
              if (i !== index) {
                dt.items.add(f);
              }
            });
            input.files = dt.files;
            this.updateIndexes();
            this.updateDropArea();
          };

          div.appendChild(img);
          div.appendChild(span);
          previewsContainer.appendChild(div);
          this.updateDropArea();
          this.updateImageOrderInput();
        }
      };
      reader.readAsDataURL(file);
    });
  }

  removeImage(event) {
    event.target.closest('.image-container').remove();
    this.updateImageOrderInput();
  }

  addImageClick(event) {
    event.preventDefault();
    this.inputTarget.click();
  }

  setupDragAndDrop() {
    const previewsContainer = this.previewsTarget;
    let draggedItem = null;

    previewsContainer.addEventListener("dragstart", (e) => {
      if (e.target.classList.contains("image-container")) {
        draggedItem = e.target;
        e.dataTransfer.effectAllowed = "move";
        e.dataTransfer.setData("text/html", e.target.outerHTML);
      }
    });

    previewsContainer.addEventListener("dragover", (e) => {
      if (e.target.classList.contains("image-container")) {
        e.preventDefault();
        e.dataTransfer.dropEffect = "move";
      }
    });

    previewsContainer.addEventListener("drop", (e) => {
      if (e.target.classList.contains("image-container") && e.target !== draggedItem) {
        e.preventDefault();
        e.stopPropagation();
        draggedItem.remove();
        e.target.insertAdjacentHTML("beforebegin", e.dataTransfer.getData("text/html"));
        this.setupDragAndDrop(); // reinitialize event listeners
        this.updateIndexes();
        this.updateDropArea();
        this.updateImageOrderInput();
      }
    });
  }

  setupSortable() {
    const previewsContainer = this.previewsTarget;
    Sortable.create(previewsContainer, {
      animation: 150,
      onEnd: (evt) => {
        this.updateIndexes();
        this.updateImageOrderInput();
      }
    });
  }

  updateIndexes() {
    const previewsContainer = this.previewsTarget;
    const imageContainers = previewsContainer.querySelectorAll(".image-container:not(.add-image-container)");
    imageContainers.forEach((container, index) => {
      container.dataset.index = index;
      if (index === 0) {
        container.classList.add("first-image");
      } else {
        container.classList.remove("first-image");
      }
    });
  }

  updateImageOrderInput() {
    const previewsContainer = this.previewsTarget;
    const imageOrderInputs = document.querySelectorAll('.image-order-input');
    const imageContainers = previewsContainer.querySelectorAll(".image-container:not(.add-image-container) img");

    imageOrderInputs.forEach((input, index) => {
      input.value = imageContainers[index].src;
    });

    const deletedImagesInput = document.getElementById('deleted-images-input');
    const deletedImages = Array.from(document.querySelectorAll('.image-container img')).filter(img => !img.closest('.image-container'));
    deletedImagesInput.value = deletedImages.map(img => img.src).join(',');
  }

  updateDropArea() {
    const previewsContainer = this.previewsTarget;
    const dropArea = this.dropAreaTarget;
    const imageContainers = previewsContainer.querySelectorAll(".image-container");

    if (imageContainers.length > 0) {
      dropArea.classList.add("small-file-drop-area");
      dropArea.querySelector(".file-msg").style.display = "none";
      dropArea.querySelector(".fake-btn").style.display = "none";
    } else {
      dropArea.classList.remove("small-file-drop-area");
      dropArea.querySelector(".file-msg").style.display = "";
      dropArea.querySelector(".fake-btn").style.display = "";
    }

    previewsContainer.appendChild(dropArea);
  }

  dropHandler(event) {
    event.preventDefault();
    event.stopPropagation();
    this.inputTarget.files = event.dataTransfer.files;
    this.previewImages();
  }

  dragOverHandler(event) {
    event.preventDefault();
    event.stopPropagation();
  }
}
