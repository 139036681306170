import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["input"]

  connect(){
    this.parseInputValue()
  }

  optionClicked(e){
    e.stopPropagation()
    let materialOption = e.currentTarget
    let id = materialOption.dataset.value
    if(materialOption.classList.contains('selected')) {
      materialOption.classList.remove('selected')
      this.removeOption(id)
    } else {
      materialOption.classList.add('selected')
      this.addOption(id)
    }
  }

  groupClicked(e){
    e.stopPropagation()
    let group = e.currentTarget
    let groupName = group.innerText
    let dropdownOptions = group.parentNode
    let options = dropdownOptions.querySelectorAll(`[data-group="${groupName}"]`)

    if(group.classList.contains('selected')){
      group.classList.remove('selected')
      options.forEach(option => {
        option.classList.remove('selected')
        this.removeOption(option.dataset.value)
      })
    } else {
      group.classList.add('selected')
      options.forEach(option => {
        option.classList.add('selected')
        this.addOption(option.dataset.value)
      })
    }
  }

  addOption(id) {
    let inputValues = this.parseInputValue()
    inputValues.push(id)
    this.setInputValues(inputValues)
  }

  removeOption(id) {
    let inputValues = this.parseInputValue()
    inputValues = inputValues.filter(i => i !== id)
    this.setInputValues(inputValues)
  }

  parseInputValue(){
    return this.inputTarget.value.split(/\s+/);
  }

  setInputValues(values){
    this.inputTarget.value = values.join(' ')
  }
}
