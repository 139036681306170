import { post } from "@rails/request.js"
import ApplicationController from "./application_controller"
export default class extends ApplicationController {
  static targets = ["part"]

  static values = {
    configId: String,
    partId: String,
    portalsDomId: String,
  }

  connect() {
    this.fetchPortals()
  }

  fetchPortals() {
    this.disableButtonsWhileLoading()

    var datas = this.partTargets.map((part) => {
      return {
        modelId: part.dataset.modelId,
        quantity: part.querySelector('input[type="number"]').value,
      }
    })

    let response = post("/selections/fetch_portals", {
      body: {
        models: JSON.stringify(datas),
        material_config_id: this.configIdValue,
        dom_id: this.portalsDomIdValue,
        part_id: this.partIdValue,
        type: "part"
      },
    })
  }

  disableButtonsWhileLoading() {
    document.querySelectorAll(".portal-select-button").forEach((button) => {
      button.disabled = true
      button.innerText = "Loading..."
    })
  }
}
