import ApplicationController from "./application_controller";

export default class extends ApplicationController {
  static targets = ["price", "total"]

  connect() {
    document.addEventListener("priceChanged", () => this.recalculateTotal())
  }

  recalculateTotal() {
    var globalTotal = 0
    this.priceTargets.forEach((price) => {
      globalTotal += this.parseFee(price.value)
    })
    if(globalTotal) {
      this.totalTarget.innerText = "€" + globalTotal.toFixed(2)
    } else {
      this.totalTarget.innerText = "€0.00" 
    }
  }

  parseFee(fee) {
    return parseFloat(fee.replace("€", ""))
  }
}
